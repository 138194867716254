//The file that builds bootstrap. It has been customized for the newspapers.com color scheme.
//Some components have been commented out as we don't use them.

@import 'bootstrap/scss/functions';
// Custom Newspapers.com colors
$gray-200: #EBEBEB; //Gray
$gray-900: #2d2d2d; //Ink
$gray-600: #4A4A4A; //Slate
$blue: #0079B7; //NCOM Blue
$purple: #833BDC; //Used for Birth
$pink: #D237B5; //Used for marriage
$red: #BB2429; //NCOM red
$orange: #D57B12; //Brand Orange
$teal: #00C993; //article Green
$yellow: #F6B040; //PE
$green: #328800; //ACOM
$danger: #B51A00;
$success: #107E2A;
$info: #7EC2EC; //Brand Blue
$primary: $blue;
$primary-bg-subtle: #E0F0F9; //Light Blue
$secondary-bg-subtle: #FDF9F1; //Beige
$info-bg-subtle: #F6FBFE; //Faint Blue
$form-floating-input-padding-b: .5rem;

@import 'bootstrap/scss/variables';
//@import 'bootstrap/scss/variables-dark';

@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/utilities/api';

// Layout & components
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
@import 'bootstrap/scss/images';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/tables';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/dropdown';
@import 'bootstrap/scss/button-group';
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/navbar';
@import 'bootstrap/scss/card';
//@import 'bootstrap/scss/accordion';
@import 'bootstrap/scss/breadcrumb';
//@import 'bootstrap/scss/pagination';
@import 'bootstrap/scss/badge';
//@import 'bootstrap/scss/alert';
//@import 'bootstrap/scss/progress';
@import 'bootstrap/scss/list-group';
@import 'bootstrap/scss/close';
@import 'bootstrap/scss/toasts';
@import 'bootstrap/scss/modal';
@import 'bootstrap/scss/tooltip';
@import 'bootstrap/scss/popover';
//@import 'bootstrap/scss/carousel';
//@import 'bootstrap/scss/spinners';
//@import 'bootstrap/scss/offcanvas';
//@import 'bootstrap/scss/placeholders';